import { SearchOutlined } from '@ant-design/icons';
import { Image, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import globalHelper from '../../helpers/functions/global.helper';
import { useSelector } from 'react-redux';
import parkService from '../../services/config/park.service';

const MasterHeader = () => {
    const park_code = process.env.REACT_APP_PARK_CODE;
    const ticketRedux = useSelector(state => state?.ticket);
    const [state, setState] = useState({
        park : null,
    })

    const getPark = async () => {
        const parkResponse = await parkService?.getPark({ park_code, is_server_maintenance : false, });
        const { parks } = parkResponse;

        setState(state => ({...state, park : parks}));
    }

    useEffect(() => {
        getPark();
    }, [])
    return (
        <>
            <ContainerComponent span={22}>
                <div style={{width:'100%',}}>
                    <div style={{width:'100%', display :'flex', justifyContent: 'start',}}>
                        <Image
                        preview={false}
                        // src={`/cibanners/logo/${park_code}.svg`}
                        src={(state?.park?.image)}
                        style={{ maxHeight: 35, maxWidth : 60, }}
                        />
                        
                        <div style={{ marginLeft: 12, }}>
                            <span
                            className='ticket-label ticket-title h1'
                            style={{ fontSize:14, color:'var(--secondary-text-color)', fontWeight:500, whiteSpace : 'nowrap', overflow:'hidden', textOverflow:'ellipsis', minWidth:100, maxWidth:200, display:'block', }}
                            >
                                {state?.park?.name}
                            </span>
                        </div>
                    </div>
                </div>
            </ContainerComponent>
        </>
    );
}

export default MasterHeader;