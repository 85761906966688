import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticketing/ticket.service';
import { LoadingOutlined, ShareAltOutlined } from '@ant-design/icons';
import DetailTicketUser from './detail_ticket_user';
import { Button, Divider, Space } from 'antd';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import DateCalendar from './date_calendar';
import { RWebShare } from 'react-web-share';
import ShareTicketUser from './share_ticket_user';
import moment from 'moment';
import ExpiredDetailTicketUser from './expired_detail_ticket_user';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const DetailLayout = ({ ticket_user_id, token, }) => {
    const [state, setState] = useState({
        loading : true,
        ticket_user : null,
        ticket : null,
        //load count, only refresh in first loading
        load_count : 0,
        //expired ticket
        is_expired : false,
    });

    const fetchTicketUser = async () => {
        setState(state => ({...state, loading : true,}))
        const ticketUserResponse = await ticketService.getTicketUser({
            ticket_user_no : ticket_user_id,
        });

        const ticket_user = ticketUserResponse?.ticket_users?.rows?.length > 0 ? ticketUserResponse?.ticket_users?.rows[0] : null;

        //check expired
        const check_expired =
        //check if ticket expired and open date is null
        // ((ticket_user?.ticket?.expired_date && !ticket_user?.open_date))
        ((ticket_user?.ticket?.is_open_date) && ((moment()?.startOf('days')?.diff(moment(ticket_user?.ticket?.expired_date)?.startOf('days'), 'days')) > 0))
        //check if today date is larger than visit date
        //2024-09-15, no need check for expired on visit date
        // hard code to 09-22
        // only for sinkang fair
        || (moment()?.startOf('days')?.diff(moment("2024-09-22"), 'days') > 0)
        // || (moment()?.startOf('days')?.diff(ticket_user?.open_date, 'days') > 0);

        const expired = check_expired;

        setTimeout(() => {
            const load_count = state?.load_count + 1;
            setState(state => ({...state, ticket_user : ticket_user, ticket: ticket_user?.ticket, loading : false, load_count : load_count, is_expired : expired, }));
        }, 500);
        
    }

    useEffect(() => {
        fetchTicketUser();
    }, [])

    return (
        <>
            <div className='mobile-padding-24'>
                {
                    state?.loading ? 
                    (
                        <>
                            <LoadingOutlined style={{fontSize:20}} />
                        </>
                    )
                    :
                    (
                        <>
                            {
                                state?.ticket_user ? 
                                (
                                    <>
                                        <div>
                                            <div style={{display:'flex',}}>
                                                <Space style={{justifyContent:'start', width:'100%'}} size={12}>
                                                    <div>
                                                        <DateCalendar is_open_date={state?.ticket?.is_open_date} date={state?.ticket?.visit_date} />
                                                    </div>
                                                    <div style={{textAlign : 'left'}}>
                                                        <div>
                                                            <PageHeaderComponent title={state?.ticket?.park?.name} />
                                                        </div>
                                                        <div>
                                                            <span style={{color:'#6f7287', fontWeight:500}}>
                                                                <Space wrap size={2}>
                                                                    <span>Ticket No:</span>
                                                                    <span>{state?.ticket_user?.ticket_user_no}</span>
                                                                </Space>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span style={{color:'#6f7287', fontWeight:500}}>
                                                                <Space wrap size={2}>
                                                                    <span>Ticket Type:</span>
                                                                    <span>
                                                                        {`${state?.ticket_user?.ticket_user_type?.name} 
                                                                        (${
                                                                            `Age ${state?.ticket_user?.ticket_user_type?.max_age ? 
                                                                                `${state?.ticket_user?.ticket_user_type?.min_age} - ${state?.ticket_user?.ticket_user_type?.max_age}` :
                                                                                `${state?.ticket_user?.ticket_user_type?.min_age} and above`
                                                                        }`
                                                                        })
                                                                        `}
                                                                    </span>
                                                                </Space> 
                                                            </span>
                                                        </div>
                                                        
                                                    </div>
                                                </Space>

                                                <div style={{marginLeft:'auto'}}>
                                                    <ShareTicketUser park={state?.ticket?.park} ticket_user_no={state?.ticket_user?.ticket_user_no}>
                                                        <Button
                                                        // onClick={() => addQuantity(ticket_user_type?.ticket_user_type_id, 1)}
                                                        type='ghost' 
                                                        style={{background:'#F8F7FA', padding:'4px 10px'}}
                                                        >
                                                            <ShareAltOutlined style={{color:'#5F78A4'}} />
                                                        </Button>
                                                    </ShareTicketUser>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{marginTop:24}}>
                                            {
                                                (!state?.ticket?.is_open_date && state?.is_expired) ?
                                                (
                                                    <>
                                                        <ExpiredDetailTicketUser ticket_user={state?.ticket_user} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <DetailTicketUser ticket_user={state?.ticket_user} token={token} />
                                                )
                                            }
                                            
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <SimpleNotFoundComponent
                                        isBack
                                        title={'Ticket Not Found'}
                                        description={`Ticket ${ticket_user_id} not found`}
                                        />
                                    </>
                                )
                            }
                            
                        </>
                    )
                }
            </div>
        </>
    );
}

export default DetailLayout;